import React, { Component } from 'react';
import Scrollchor from 'react-scrollchor';


class GenerateurHero extends Component {
  render() {
    return (
      // <section className="fdb-block fdb-viewport bg-dark" style={{ backgroundImage: 'url(imgs/hero/purple.svg)' }}>
      <section className="fdb-block fdb-viewport bg-dark bg-gradient-primary pt-126">
        <div className="container">
          <div className="row text-left">
            <div className="col-12 col-sm-10 col-md-8 col-lg-8">
              <h1>{this.props.title}</h1>
              <p className="lead">{this.props.subtitle}</p>
              <p className="mt-5">
                <Scrollchor to="#pseudo-gen" className="btn btn-light">Générer</Scrollchor>
                <Scrollchor to="#pseudo-list" className="btn btn-dark ml-4">Voir la liste</Scrollchor>
              </p>
            </div>
          </div>
        </div>
      </section>
    )

  }
}

export default GenerateurHero
