import React, { Component } from 'react';


class GenerateurListPseudo extends Component {
  render() {
    return (
        <section className="fdb-block bg-light" id="pseudo-list">
        <div className="container">
          <div className="col">
            <div className="row">
              <h2 className="text-center">{this.props.title}</h2>
            </div>
            <div className="row">
              {this.props.children}
            </div>
          </div>
        </div>
      </section>
    )

  }
}

export default GenerateurListPseudo
