import React, { Component } from 'react';

class GenerateurListPseudoBox extends Component {
  render() {
    return (

      <div className="col-12 col-sm-12 col-md-6 pt-5">
        <div className="fdb-box fdb-touch">
          <p className="h2 text-center">{this.props.pseudo}</p>
        </div>
      </div>
    )

  }
}

export default GenerateurListPseudoBox
