import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Scrollchor from 'react-scrollchor';



class GenerateurGen extends Component {

  constructor(props) {
    super(props);
    this.state = {
      pseudo: ""
    };

    this.onButtonClicked = this.onButtonClicked.bind(this);
  }

  onButtonClicked() {
    let randIndex = Math.floor(Math.random()*this.props.pseudos.length);    
    this.setState(state => ({
      pseudo : this.props.pseudos[randIndex].node.name
    }))
  }

  render() {
    return (
      <>
        <section className="fdb-block" id="pub">
          <div className='container'>
            <div className='row justify-content-center'>
              <ins className="adsbygoogle"
                  data-ad-client="ca-pub-6801750087232009"
                  data-ad-slot="8149540418"
                  data-ad-format="auto"
                  data-full-width-responsive="true"></ins>
              <script>
                  (adsbygoogle = window.adsbygoogle || []).push({});
              </script>
              {/* <div id="96555-1"><script src="//ads.themoneytizer.com/s/gen.js?type=1"></script><script src="//ads.themoneytizer.com/s/requestform.js?siteId=96555&formatId=1"></script></div> */}
            </div>
          </div>
        </section>
        <section className="fdb-block" id="pseudo-gen">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-12 col-md-9 col-lg-8">
                <div className="fdb-box fdb-touch">
                  <div className="row justify-content-center">
                    <div className="col-12 col-lg-10">
                      <h2 className="text-h1">Générer un pseudo</h2>
                      <p className="lead">Clique sur le bouton pour générer un nouveau pseudo</p>
                    </div>
                  </div>
                  <div className="row justify-content-center mt-4">
                    <p className="h1">{this.state.pseudo}</p>
                  </div>
                  <div className="row justify-content-center mt-4">
                    <button 
                      className="btn btn-primary btn-lg btn-block"
                      type="button" 
                      onClick={this.onButtonClicked}>Générer
                    </button>
                    <Scrollchor to="pseudo-list" className="mt-4">Ou voir la liste complète...</Scrollchor>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="fdb-block" id="pub">
          <div className='container'>
            <div className='row justify-content-center'>
            <ins className="adsbygoogle"
              data-ad-format="autorelaxed"
              data-ad-client="ca-pub-6801750087232009"
              data-ad-slot="1584132069"></ins>
            <script>
                (adsbygoogle = window.adsbygoogle || []).push({});
            </script>
              {/* <div class="outbrain-tm" id="96555-16"><script src="//ads.themoneytizer.com/s/gen.js?type=16"></script><script src="//ads.themoneytizer.com/s/requestform.js?siteId=96555&formatId=16"></script></div> */}
            </div>
          </div>
        </section>
        </>
    )

  }
}

GenerateurGen.propTypes = {
  pseudos: PropTypes.array.isRequired
};

export default GenerateurGen
